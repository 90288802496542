import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import FormEdit from "./FormEdit";
import queryString from "query-string";

const Index = () => {
  return (
    <Location>
      {({ location, navigate }) => (
        <FormEdit
          location={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
        ></FormEdit>
      )}
    </Location>
  );
};

export default Index;
