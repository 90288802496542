import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  addQuestion,
  fetchQuestions,
  removeQuestion,
  fetchOptions,
} from "../../../../actions";

import Layout from "../../../../components/layout";
import { Divider, Segment } from "semantic-ui-react";

// import { TableBIM, TableFlow, TableAudit } from "../../components/index";
import { AddNewQuestion, FlowFormEdit } from "../../../../components/index";

const FormEdit = ({ location, navigate, search }) => {
  const dispatch = useDispatch();
  const [question_name, update_question_name] = useState("");
  const [options_array, update_options_array] = useState([]);

  const [type_selected, update_type_selected] = useState(null);

  const questions = useSelector((state) => state.questions);
  const options = useSelector((state) => state.options);

  const types = [
    { id: "1", name: "Buttons" },
    { id: "2", name: "Buttons With Option" },
    { id: "3", name: "Text Field" },
    { id: "4", name: "Text Box" },
  ];

  function handleAddQuestion() {
    dispatch(
      addQuestion({
        name: question_name,
        flow_id: search.id,
        type: type_selected,
        options_array: JSON.stringify(options_array),
        include_other_option: true,
        text: "asd",
      })
    );
    update_question_name("");
    update_options_array([]);
    dispatch(fetchOptions());
  }

  function handleRemoveQuestion(question) {
    dispatch(removeQuestion(question));
  }

  useEffect(() => {
    dispatch(fetchQuestions(search.id));
    dispatch(fetchOptions());
  }, []);

  const add = {
    placeholder: "Question Name",
    value: question_name,
    onChange: update_question_name,
    add: handleAddQuestion,
    button_text: "Add Question",
  };

  function add_option(value) {
    const option = _.find(options, { id: value });
    _.remove(options, { id: value });
    const new_array = [...options_array, option];
    update_options_array(new_array);
  }
  return (
    <Layout>
      <div>
        <Segment secondary>
          <div>{JSON.stringify(options_array)}</div>
          <div>{type_selected}</div>
          <AddNewQuestion
            placeholder={add.placeholder}
            value={add.value}
            onChange={add.onChange}
            add={add.add}
            button_text={add.button_text}
            options={options}
            add_option={add_option}
            types={types}
            update_type_selected={update_type_selected}
            type_selected={type_selected}
          />
        </Segment>
        {/* <div>{JSON.stringify(options_array)}</div> */}

        <Divider />
        <Divider />
        {questions !== null ? (
          <>
            <FlowFormEdit questions={_.orderBy(questions, "createdAt")} />
          </>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default FormEdit;
